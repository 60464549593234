<template>
  <v-snackbar v-model="snackbar.isOpen" :color="snackbarColor">
    {{ snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="closeSnackbarHandler">
        {{ $t("snackbar.close") }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";
import { SNACKBAR_TYPES } from "../../constants";

export default {
  name: "snackbar",
  computed: {
    ...mapGetters(["snackbar"]),
    snackbarColor() {
      return this.snackbar.type === SNACKBAR_TYPES.ERROR ? "red" : "green";
    },
  },
  methods: {
    closeSnackbarHandler() {
      this.$store.dispatch("setSnackbar", {
        ...this.snackbar,
        isOpen: false,
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>