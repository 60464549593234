import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";


export default {
    namespaced: true,
    state(){
        return{
            getTimeLoggedPaginated:[],
            totalNumberOfTimeLogged: 0,
            totalNumberOfTimeLoggedPages: 0,
            employeesLeaveRequests:[],
            totalNumberOfLeaveRequests:0,
            totalNumberOfLeaveRequestsPages:0,
            putEmployeeLeaveAction:[]

         
            
        }
    },
    mutations,
    actions,
    getters,
}