export default {
	employees: (state) =>
		state.employees.map((emp) => ({
			id: emp.id,
			firstName: emp.firstName,
			lastName: emp.lastName,
			department: emp.department.name,
			jobTitle: emp.jobTitle.title,
			location: emp.location.name,
			currency: emp.currency // to be edited
		})),
	totalNumberOfEmployees: (state) => state.totalNumberOfEmployees,
	totalNumberOfPages: (state) => state.totalNumberOfPages,
	employee: (state) => state.employee,
	departments: (state) => state.departments,
	jobTitles: (state) => state.jobTitles,
	locations: (state) => state.locations,
	currency:(state)=>state.currency,
	uploadEmplyees:(state)=> state.uploadEmplyees,
	postUploadedEmployee:(state)=> state.postUploadedEmployee,
	updateUploadedEmployee:(state)=> state.updateUploadedEmployee,
	getEmployeeHistory:(state)=>state.getEmployeeHistory,
	getRequiredDocuments:(state)=>state.getRequiredDocuments,
	getDocumentsPaginated:(state)=>state.getDocumentsPaginated,
	totalNumberOfDocs:(state)=>state.totalNumberOfDocs,
	totalNumberOfDocsPages:(state)=>state.totalNumberOfDocsPages,
	postRequiredDocuments:(state)=>state.postRequiredDocuments,
	getUploadedDocuments:(state)=>state.getUploadedDocuments,
	deleteUploadedDocuments:(state)=>state.deleteUploadedDocument,
	addRequiredDocuments:(state)=>state.addRequiredDocuments,
	editRequiredDocuments:(state)=>state.editRequiredDocuments,


};