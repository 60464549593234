export const KEYKLOACK_URL = {
	STAGING: "https://keycloak-staging.ntsal.dev/auth/",
	PRODUCTION: "https://keycloak-staging.ntsal.dev/auth/"
};

export const APIs_URL = {
	STAGING: "http://64.225.104.110:8080/api/",
	PRODUCTION: "/api"
};

// http://64.225.104.110

export const SNACKBAR_TYPES = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR"
};


// tenant-283034
