import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
	namespaced: true,
	state() {
		return {
			employees: [],
			totalNumberOfEmployees: 0,
			totalNumberOfPages: 0,
			employee: null,
			departments: [],
			locations: [],
			jobTitles: [],
			currency:[],
			uploadEmplyees:[],
			postUploadedEmployee:[],
			updateUploadedEmployee:[],
			getEmployeeHistory:[],
			getRequiredDocuments:[],
			getDocumentsPaginated:[],
			totalNumberOfDocs: 0,
			totalNumberOfDocsPages: 0,
			postRequiredDocuments:[],
			getUploadedDocuments:[],
			deleteUploadedDocuments:[],
			addRequiredDocuments:[],
			editRequiredDocuments:[],



		};
	},
	mutations,
	actions,
	getters
};
