import axios from "axios";
import Parser from "./parser.js";

export default class NetworkManager {
  static async getPaginated(apiName, modelName, paginationRequest) {
    return await axios
      .post(apiName, paginationRequest)
      .then(response => {
        return {
          results: Parser.parse(modelName, response.data.response.result),
          totalNumberOfElements: response.data.response.totalNumberOfElements,
          totalNumberOfPages: response.data.response.totalNumberOfPages
        };
      })
  }

  static async get(apiName, modelName) {
    return await axios
      .get(apiName)
      .then(response => {
        // Add conditional parameter for export csv, the response is just "response.data"
        return Parser.parse(modelName, response.data);
      })
  }

  static async post(apiName, body, modelName) {
    return await axios
      .post(apiName, body)
      .then(response => {
        return Parser.parse(modelName, response.data);
      })
  }

  static async patch(apiName, body, modelName) {
    return await axios
      .patch(apiName, body)
      .then(response => {
        return Parser.parse(modelName, response.data);
      })
  }

  static async delete(apiName, payload, modelName) {
    return await axios
      .delete(apiName, payload)
      .then(response => {
        return Parser.parse(modelName, response.data);
      })
  }

  static async put(apiName, payload, modelName) {
    return await axios
      .put(apiName, payload)
      .then(response => {
        return Parser.parse(modelName, response.data);
      })
  }

  static async download(apiName) {
    return await axios
      .get(apiName, {
        responseType: "blob"
      })
      .then(response => {
        return response.data;
      })
  }
}
