import vuetifyAr from "vuetify/lib/locale/ar";

const ar = {
  $vuetify: {
    ...vuetifyAr,
  },
  form: {
    is_required: "مطلوب",
    must_be_valid: "يجب أن تكون صالحة",
  },
  datatable: {
    search: "ابحث...",
    loading: "جاري التحميل انتظر من فضلك",
    actions: "الأدوات",
  },
  buttons: {
    save: "تحديث",
    cancel: "الغاء",
    choose_file: "رفع ملف",
    submit: "إرسال",
  },
  actions: {
    logout: "تسجيل خروج",
    export: "تحميل",
    done: "تم",
  },
  bool: {
    yes: "نعم",
    no: "لا",
  },
  locales: {
    en: "الإنجليزية",
    ar: "العربية",
  },
  InputSelect: {
    options: "خيارات محددة",
  },
  rules: {
    please_enter: "برجاء ادخال",
    only_letters: "مكون من حروف فقط",
    only_numbers: "يجب ان يكون مكون من ارقم فقط",
    please_enter_positive_value: "برجاء ادخال رقم موجب",
    at_least_digits_letters: "حروف او ارقام على الاقل",
    at_least_digits: "ارقام على الاقل",
    email_invalid: "بريد إلكتروني خاطئ",
    phone_invalid: "رقم غير صحيح",
    passwordConfirm: "كلمه المرور غير صحيحة",
    please_enter_range_from: "برجاء ادخال من",
    please_enter_range_to: "الي",
    please_enter_at_least: "من فضلك ادخل عالأقل",
    One_uppercase_character: "يجب ان يحتوى على حرف كبير",
    One_lowercase_character: "يجب ان يحتوى على حرف صغير",
    One_number: "يجب ان يحتوى على رقم ",
    special_character: "يجب ان يحتوى على رمز مثل: # - @ ....",
  },
  snackbar: {
    close: "اغلاق",
  },
  employees: {
    pageTitle: "الموظفين",
    uploadEmployeeTitle: "إضافة موظفين",
    searchTxt: "بحث",
    reset: "مسح",
    id: " تعريف",
    email: "البريد الإلكتروني",
    firstName: "الاسم الاول",
    lastName: "اللقب",
    department: "قسم",
    jobTitle: "مسمى وظيفي",
    location: "موقع",
    actions: "أجراءات",
    newEmployee: "موظف جديد",
    createNewEmployee:" إضافه موظف جديد",
    uploadNewEmployee:"رفع ملف موظفين",
    requiredDocuments: "حدد المستندات المطلوبة",
    listRequiredDocuments: "المستندات المطلوبة",
    addRequiredDocuments:"إضافة المستندات المطلوبة",
    createNewEmployeeTitle: "إنشاء موظف جديد",
    updateEmployeeTitle: "بيانات الموظف",
    history: "سِجل الموظف",
    filters: {
      departmentsPlaceholder: "حدد القسم (الأقسام)",
      locationsPlaceholder: "حدد الموقع (المواقع)",
      clientNamePlaceholder: "حدد إسم العميل",
      jobTitlesPlaceholder: "حدد الوظيفة",
      firstNamePlaceholder: "أكتب الأسم الأول",
      lastNamePlaceholder: "أكتب الأسم الثاني",
    },
    employeeForm: {
      fName: {
        label: "الاسم الاول",
        placeholder: "إدخل الاسم الأول",
      },
      middleName: {
        label: "الاسم الوسطى",
        placeholder: "إدخل الاسم الأوسط",
      },
      lName: {
        label: "اللقب",
        placeholder: "إدخل اسم الأخير",
      },
      email: {
        label: "البريد الإلكتروني",
        placeholder: "ُإدخل البريد الإلكتروني",
      },
      phone: {
        label: "هاتف",
        placeholder: "إدخل الهاتف",
      },
      jobTitle: {
        label: "المسمى الوظيفي",
        placeholder: "إدخل المسمى الوظيفي",
      },
      location: {
        label: "الموقع",
        placeholder: "إدخال الموقع",
      },
      joiningDate: {
        label: "تاريخ الانضمام",
        placeholder: "إدخل تاريخ الانضمام",
      },
      department: {
        label: "القسم",
        placeholder: "إدخل القسم",
      },
      levelNumber: {
        label: "رقم المستوى",
        placeholder: "إدخل رقم المستوى",
      },
      altEmail: {
        label: "بريد الكتروني بديل",
        placeholder: "إدخل بريد الكتروني بديل",
      },
      creationDate: {
        label: "تاريخ الإنشاء",
        placeholder: "إدخل تاريخ الإنشاء",
      },
      contractExpiryDate: {
        label: "تاريخ انتهاء العقد",
        placeholder: "إدخل تاريخ انتهاء العقد",
      },
      birthDate: {
        label: "تاريخ الميلاد",
        placeholder: "إدخل تاريخ الميلاد",
      },
      employeeStatus: {
        label: "حالة الموظف",
        placeholder: "إدخل حالة الموظف",
      },
      grossSalary: {
        label: "الراتب الكلى",
        placeholder: "إدخل الراتب الكلى",
      },
      maritalStatus: {
        label: "الحالة الإجتماعية",
        placeholder: "إدخل الحالة الإجتماعية",
      },
      militaryStatus: {
        label: "موقف الخدمة العسكرية",
        placeholder: "إدخل موقف موقف الخدمة العسكرية",
      },
      socialInsuranceNumber: {
        label: "رقم التآمين الإجتماعي",
        placeholder: "إدخل رقم التآمين الإجتماعي",
      },
      workId: {
        label: "الرقم التعريفي",
        placeholder: "إدخل الرقم التعريفي",
      },
      currency: {
        label: "العملة",
        placeholder: "إدخل العملة",
      },
      msgs: {
        create: "تم اضافة موظف بنجاح",
        update: "تم تعديل موظف بنجاح",
      },
      btns: {
        create: "انشاء",
        update: "تحديث",
      },
      requiredDocfieldName: {
        label: "إسم الحقل",
        placeholder: "إدخل إسم الحقل",
      },
    },
  },
  projects: {
    pageTitle: "المشروعات",
    projectDetails: "تفاصيل المشروع",
    addProject: "إضافه مشروع",
    upateProject: "تعديل مشروع",
    id: "التعريف",
    name: "الإسم",
    email: "البريد الآلكتروني",
    loggedHours: "الساعات المسجلة",
    description: "الوصف",
    clientName: "إسم العميل",
    department: "القسم",
    assignEmployees: "تعيين الموظفين",
    chooseEmployeesToAssign: "اختر موظفين للتعيين بالمشروع",
    assignedEmployees: "الموظفين المعينيين بالمشروع",
    showDetails: "إظهار التفاصيل",
    loggedTime: "الوقت المسجل",
    edit: "تعديل",
    projectForm: {
      role: {
        title: "إضافه وظيفة",
        label: "الوظيفة",
        placeholder: "إدخل الوظيفة",
        update: "تعديل الوظيفة",
      },
      description: {
        label: "الوصف",
        placeholder: "إدخل الوصف",
      },
      client: {
        title: "إضافه عميل",
        label: "إسم العميل",
        placeholder: "إدخل إسم العميل",
        update: "تعديل إسم العميل",
      },
      name: {
        label: "إسم المشروع",
        placeholder: "إدخل إسم المشروع",
      },
    },
  },
  timeLogged:{
    employeeEmail:"بريد الكتروني الموظف",
    employee:"الموظف",
    project:"المشروع",
    totalHours:"عدد الساعات",
    employeeComment:"تعليق الموظف",
    adminComment:"تعليق المسئول",
    leave:"مغادرة",
    date:"التاريخ",
    from:"من",
    to:"إلي",
    SelectProject:"إختر المشروع",
    selectStatus:"إختر الحالة",
    selectType:"إختر النوع",
    type:"السبب",
    status:"الحالة",
    action:"الإجراء"


  },
  employeeLeaves:{
    EmployeeLeavesTitle:"طلبات إجازات الموظفين",
  }
};

export default ar;
