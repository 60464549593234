import {
  GET_TIME_LOGGED_PAGINATED,
  GET_EMPLOYEE_LEAVE_REQUESTS,
  PUT_EMPLOYEE_LEAVE_ACTION
} from "../../mutation-types";

export default {
  [GET_TIME_LOGGED_PAGINATED](state, payload) {
    state.getTimeLoggedPaginated = payload.getTimeLoggedPaginated;
    state.totalNumberOfTimeLogged = payload.totalNumberOfTimeLogged;
    state.totalNumberOfTimeLoggedPages = payload.totalNumberOfTimeLoggedPages;
  },
  [GET_EMPLOYEE_LEAVE_REQUESTS](state, payload) {
    state.employeesLeaveRequests = payload.employeesLeaveRequests;
    state.totalNumberOfLeaveRequests = payload.totalNumberOfLeaveRequests;
    state.totalNumberOfLeaveRequestsPages =  payload.totalNumberOfLeaveRequestsPages;
  },
  [PUT_EMPLOYEE_LEAVE_ACTION](state, payload) {
    state.putEmployeeLeaveAction = payload;
  }

};
