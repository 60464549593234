import axios from "axios";
import NetworkManager from "../../../network/network-manager";
import {
  SET_DEPARTMENTS,
  SET_EMPLOYEE,
  SET_EMPLOYEE_TABLE,
  SET_JOB_TITLES,
  SET_LOCATIONS,
  SET_CURRENCY,
  UPLOAD_EMPLOYEE,
  ADD_UPLOEADED_EMPLOYEE,
  UPDATE_UPLOEADED_EMPLOYEE,
  GET_EMPLOYEE_HISTORY,
  GET_REQUIRED_DOCUMENTS,
  GET_DOCUMENTS_PAGINATED,
  ADD_REQUIRED_DOCUMENTS,
  EDIT_REQUIRED_DOCUMENTS,
  POST_REQUIRED_DOCUMENTS,
  GET_UPLOADED_DOCUMENTS,
  DELETE_UPLOADED_DOCUMENTS,
} from "../../mutation-types";
export default {
  async getEmployeeData({ commit }, payload) {
    const res = await axios.post(
      "/employee/find-all-paginated-filtered",
      payload
    );
    const response = res.data.response;
    commit(SET_EMPLOYEE_TABLE, {
      employees: response.result,
      totalNumberOfEmployees: response.totalNumberOfElements,
      totalNumberOfPages: response.totalNumberOfPages,
    });
  },
  async createEmployee({ commit }, payload) {
    const res = await axios.post("employee", payload);
    commit(
      SET_EMPLOYEE,
      res.data.response &&
        res.data.response.success &&
        res.data.response.success[0]
    );
  },
  async updateEmployee({ commit }, payload) {
    const res = await axios.put("employee", payload);
    commit(
      SET_EMPLOYEE,
      res.data.response &&
        res.data.response.success &&
        res.data.response.success[0]
    );
  },
  async getEmployeeByID({ commit }, { id }) {
    const res = await axios.get(`employee/${id}`);
    commit(SET_EMPLOYEE, res.data.response);
  },
  async getDepartments({ commit }) {
    const res = await axios.get("department");
    commit(SET_DEPARTMENTS, res.data.response);
  },
  async getLocations({ commit }) {
    const res = await axios.get("location");
    commit(SET_LOCATIONS, res.data.response);
  },
  async getJobTitles({ commit }) {
    const res = await axios.get("job-title");
    commit(SET_JOB_TITLES, res.data.response);
  },
  async getCurrency({ commit }) {
    const res = await axios.get("currency");
    commit(SET_CURRENCY, res.data.response);
  },
  async uploadEmployeeData(context, payload) {
    const response = await NetworkManager.post(`/employee/upload`, payload);
    context.commit(UPLOAD_EMPLOYEE, response);
  },
  async postUploadedEmployee(context, payload) {
    const response = await NetworkManager.post(
      `employee/create/multiple`,
      payload
    );
    context.commit(ADD_UPLOEADED_EMPLOYEE, response);
  },

  async UpdateUploadedEmployee(context, payload) {
    const response = await NetworkManager.put(
      `employee/update/multiple`,
      payload
    );
    context.commit(UPDATE_UPLOEADED_EMPLOYEE, response);
  },
  async getEmployeeHistory({ commit }, { id }) {
    const res = await axios.get(`employee-history/${id}`);
    commit(GET_EMPLOYEE_HISTORY, res.data.response);
  },
  async getRequiredDocuments({ commit }) {
    const res = await axios.get(`document/find-all`);
    commit(GET_REQUIRED_DOCUMENTS, res.data.response);
  },
  async getDocumentsPaginated({ commit }, payload) {
    const res = await axios.post(`document/find-all-paginated` , payload);
    const response = res.data.response;
    commit(GET_DOCUMENTS_PAGINATED, {
      getDocumentsPaginated: response.result,
      totalNumberOfDocs:response.totalNumberOfElements ,
      totalNumberOfDocsPages: response.totalNumberOfPages
    });
  },
  async addRequiredDocuments(context, payload) {
    const response = await NetworkManager.post(
      `/document/create-list`,
      payload
    );
    context.commit(ADD_REQUIRED_DOCUMENTS, response);
  },
  async editRequiredDocuments(context, payload) {
    const response = await NetworkManager.put(
      `/document/update-list`,
      payload
    );
    context.commit(EDIT_REQUIRED_DOCUMENTS, response);
  },
  async postRequiredDocuments(context, payload) {
    const response = await NetworkManager.post(
      `employee-document/upload-admin`,
      // /employee-document/upload-admin
      payload
    );
    context.commit(POST_REQUIRED_DOCUMENTS, response);
  },
  async getUploadedDocuments({ commit }, { id }) {
    const res = await axios.get(`employee-document/employee/${id}`);
    commit(GET_UPLOADED_DOCUMENTS, res.data.response);
  },
  async deleteUploadedDocuments(context, payload) {
    const response = await NetworkManager.delete(
      `employee-document/${payload}`
    );
    context.commit(DELETE_UPLOADED_DOCUMENTS, response);
  },

  // DELETE_UPLOADED_DOCUMENTS
};
