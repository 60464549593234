import {
  // PROJECT ROLES
  POST_PROJECT_ROLES,
  GET_ALL_PROJECT_ROLES,
  GET_PROJECT_ROLES,
  GET_ROLE_BY_ID,
  EDIT_ROLE,
  // PROJECT CLIENTS
  POST_PROJECT_CLIENTS,
  GET_ALL_PROJECT_CLIENTS,
  GET_PROJECT_CLIENTS,
  GET_CLIENT_BY_ID,
  EDIT_CLIENT,
  // //
  GET_ALL_PROJECTS,
  GET_ALL_PROJECTS_PAGINATED,
  GET_SINGLE_PROJECT,
  ADD_PROJECT,
  EDIT_PROJECT,
  ASSIGN_EMPLOYEES_TO_PROJECT,
  GET_NOT_ASSIGNED_EMPLOYEES,
  GET_TIME_LOGGED_PER_PROJECT,
} from "../../mutation-types";

export default {
  // PROJECT ROLES
  [POST_PROJECT_ROLES](state, payload) {
    state.postProjectRoles = payload;
  },
  [GET_ALL_PROJECT_ROLES](state, payload) {
    state.getAllProjectRoles = payload;
  },
  [GET_PROJECT_ROLES](state, payload) {
    state.getProjectRoles = payload.getProjectRoles;
    state.totalNumberOfRoles = payload.totalNumberOfRoles;
    state.totalNumberOfRolesPages = payload.totalNumberOfRolesPages;
  },
  [GET_ROLE_BY_ID](state, payload) {
    state.getRoleById = payload;
  },
  [EDIT_ROLE](state, payload) {
    state.editRole = payload;
  },
  // PROJECT CLIENTS

  [POST_PROJECT_CLIENTS](state, payload) {
    state.postProjectClients = payload;
  },
  [GET_ALL_PROJECT_CLIENTS](state, payload) {
    state.getAllProjectClients = payload;
  },
  [GET_PROJECT_CLIENTS](state, payload) {
    state.getProjectClients = payload.getProjectClients;
    state.totalNumberOfClients = payload.totalNumberOfClients;
    state.totalNumberOfClientsPages = payload.totalNumberOfClientsPages;
  },
  [GET_CLIENT_BY_ID](state, payload) {
    state.getClientById = payload;
  },
  [EDIT_CLIENT](state, payload) {
    state.editClient = payload;
  },

  //  // //
  [GET_ALL_PROJECTS](state, payload) {
    state.getAllProjects = payload;
  },

  [GET_ALL_PROJECTS_PAGINATED](state, payload) {
    state.projects = payload.projects;
    state.totalNumberOfProjects = payload.totalNumberOfProjects;
    state.totalNumberOfProjectPages = payload.totalNumberOfProjectPages;
  },
  [GET_SINGLE_PROJECT](state, payload) {
    state.getSingleProject = payload;
  },
  [ADD_PROJECT](state, payload) {
    state.addProject = payload;
  },
  [ASSIGN_EMPLOYEES_TO_PROJECT](state, payload) {
    state.assignEmployeesToProject = payload;
  },
  [EDIT_PROJECT](state, payload) {
    state.editProject = payload;
  },
  [GET_NOT_ASSIGNED_EMPLOYEES](state, payload) {
    // state.getEmployeesNotAssignedToProjects = payload
    state.notAssignedEmployees = payload.notAssignedEmployees;
    state.totalNumberOfnotAssignedEmployees =
      payload.totalNumberOfnotAssignedEmployees;
    state.totalNumberOfPagesOfNotAssignedEmployees =
      payload.totalNumberOfPagesOfNotAssignedEmployees;
  },
  [GET_TIME_LOGGED_PER_PROJECT](state, payload) {
    state.getTimeLoggedPerProject = payload;
  },
  // GET_TIME_LOGGED_PER_PROJECT

  // notAssignedEmployees,
  // totalNumberOfnotAssignedEmployees,
  // totalNumberOfPages,

  // result
  //   totalNumberOfElements: 4
  // totalNumberOfPages: 1
};