import axios from "axios";
import NetworkManager from "../../../network/network-manager";
import {
    // PROJECT ROLES
  POST_PROJECT_ROLES,
  GET_ALL_PROJECT_ROLES,
  GET_PROJECT_ROLES,
  GET_ROLE_BY_ID,
  EDIT_ROLE,
  // PROJECT CLIENTS
  POST_PROJECT_CLIENTS,
  GET_ALL_PROJECT_CLIENTS,
  GET_PROJECT_CLIENTS,
  GET_CLIENT_BY_ID,
  EDIT_CLIENT,
  //
  GET_ALL_PROJECTS,
  GET_ALL_PROJECTS_PAGINATED,
  GET_SINGLE_PROJECT,
  ADD_PROJECT,
  ASSIGN_EMPLOYEES_TO_PROJECT,
  EDIT_PROJECT,
  GET_NOT_ASSIGNED_EMPLOYEES,
  GET_TIME_LOGGED_PER_PROJECT
} from "../../mutation-types";

export default {
    // PROJECT ROLES

  async postProjectRoles(context, payload) {
    const response = await NetworkManager.post(`/project/role`, payload);
    context.commit(POST_PROJECT_ROLES, response);

  },

  async getAllProjectRoles(context) {
    const response = await NetworkManager.get(`/project/role/all`);
    context.commit(GET_ALL_PROJECT_ROLES, response.response);
  },

  async getProjectRoles({ commit }, payload) {
    const res = await axios.post(
      `/project/role/find-all-paginated`,
      payload
    );
    const response = res.data.response;
    commit(GET_PROJECT_ROLES, {
      getProjectRoles: response.result,
      totalNumberOfRoles: response.totalNumberOfElements,
      totalNumberOfRolesPages: response.totalNumberOfPages,
    });

  },
  async getRoleById(context, payload){
    const response = await NetworkManager.get(`/project/role/${payload}` );
    context.commit(GET_ROLE_BY_ID, response.response);

  },
  async editRole(context, payload) {
    const response = await NetworkManager.put(`project/role`, payload);
    context.commit(EDIT_ROLE, response);
  },
  // PROJECT CLIENTS
  async postProjectClients(context, payload) {
    const response = await NetworkManager.post(`/project/client`, payload);
    context.commit(POST_PROJECT_CLIENTS, response);
  },
  async getAllProjectClients(context) {
    const response = await NetworkManager.get(`/project/client/all`);
    context.commit(GET_ALL_PROJECT_CLIENTS, response.response);
  },
  async getProjectClients({ commit }, payload) {
    const res = await axios.post(
      `/project/client/find-all-paginated`,
      payload
    );
    const response = res.data.response;
    commit(GET_PROJECT_CLIENTS, {
      getProjectClients: response.result,
      totalNumberOfClients: response.totalNumberOfElements,
      totalNumberOfClientsPages: response.totalNumberOfPages,
    });
  },
  async getClientById(context, payload){
    const response = await NetworkManager.get(`/project/client/${payload}` );
    context.commit(GET_CLIENT_BY_ID, response.response);
  },
  async editClient(context, payload) {
    const response = await NetworkManager.put(`project/client`, payload);
    context.commit(EDIT_CLIENT, response);
  },
  /////////
  async getAllProjects(context) {
    const response = await NetworkManager.get(`/project/all`);
    context.commit(GET_ALL_PROJECTS, response.response);
  },

  async getSingleProject(context, payload) {
    const response = await NetworkManager.get(`/project/${payload}`);
    context.commit(GET_SINGLE_PROJECT, response.response);
  },

  async getAllProjectsFilteredPaginbated({ commit }, payload) {
    const response = await axios.post(
      `/project/find-all-paginated-filtered`,
      payload
    );
    const res = response.data.response;
    commit(GET_ALL_PROJECTS_PAGINATED, {
      projects: res.result,
      totalNumberOfProjects: res.totalNumberOfElements,
      totalNumberOfProjectPages: res.totalNumberOfPages,
    });
  },

  async addProject(context, payload) {
    const response = await NetworkManager.post(`/project/`, payload);
    context.commit(ADD_PROJECT, response);
  },
  async editProject(context, payload) {
    const response = await NetworkManager.put(`/project/`, payload);
    context.commit(EDIT_PROJECT, response);
  },
  async assignEmployeesToProject(context, payload) {
    const response = await NetworkManager.post(
      `/project/assign/employee-list`,
      payload
    );
    context.commit(ASSIGN_EMPLOYEES_TO_PROJECT, response);
  },
  async getEmployeesNotAssignedToProjects({ commit }, payload) {
    const res = await axios.post(
      `/project/employee/find-all-paginated-filtered-not-assigned/${payload.id}`,
      payload.body
    );
    const response = res.data.response;
    commit(GET_NOT_ASSIGNED_EMPLOYEES, {
      notAssignedEmployees:
       response.result.map((item) => {
        return {
          ...item,
          role: null,
          isEvaluator: null,
          project: payload.id,
        };
      }),

      totalNumberOfnotAssignedEmployees: response.totalNumberOfElements,
      totalNumberOfPagesOfNotAssignedEmployees: response.totalNumberOfPages,
    });
  },
  async getTimeLoggedPerProject(context, payload) {
    const response = await NetworkManager.get(
      `/time-log/stats/project/${payload}`
    );
    context.commit(GET_TIME_LOGGED_PER_PROJECT, response.response);
  },
};
