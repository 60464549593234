export default {
    getTimeLoggedPaginated: (state) => state.getTimeLoggedPaginated,
    totalNumberOfTimeLogged: (state) => state.totalNumberOfTimeLogged,
    totalNumberOfTimeLoggedPages: (state) => state.totalNumberOfTimeLoggedPages,
    employeesLeaveRequests:(state)=> state.employeesLeaveRequests,
    totalNumberOfLeaveRequests:(state)=> state.totalNumberOfLeaveRequests,
    totalNumberOfLeaveRequestsPages:(state)=> state.totalNumberOfLeaveRequestsPages,
    putEmployeeLeaveAction:(state)=> state.putEmployeeLeaveAction





}