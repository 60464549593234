import axios from "axios";
import updateToken from '@/middlewares/update-token';
import store from "../store";
import { APIs_URL, SNACKBAR_TYPES } from "../constants";
import { vueConfig } from '../../vue.config'

axios.defaults.baseURL =
	process.env.NODE_ENV === "production"
		? APIs_URL.PRODUCTION
		: APIs_URL.STAGING;

axios.interceptors.request.use(async function(config) {
	const token = await updateToken();
	config.headers.common["Authorization"] = `Bearer ${token}`;
	config.headers["X-TENANT-ID"] = "tenant-283034"; 

	// config.headers["Content-Type"] = "multipart/form-data"; 

	// X-TENANT-ID: tenant-283034
	//staging
	//Content-Type multipart/form-data
	return config;
});

axios.interceptors.response.use(
	function(response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	},
	function(error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		const msg = error.response.data.message || "Something wrong happened";
		store.dispatch("setSnackbar", {
			isOpen: true,
			text: msg,
			type: SNACKBAR_TYPES.ERROR
		});
		throw new Error(msg);
		// return Promise.reject(error);
	}
);
