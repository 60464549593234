import axios from "axios";
import NetworkManager from "../../../network/network-manager";
import { GET_TIME_LOGGED_PAGINATED , GET_EMPLOYEE_LEAVE_REQUESTS , PUT_EMPLOYEE_LEAVE_ACTION } from "../../mutation-types";

export default {
  async getTimeLoggedPaginated({ commit }, payload) {
    const response = await axios.post(
      `/time-log/find-all-paginated-filtered`,
      payload
    );
    const res = response.data.response;
    commit(GET_TIME_LOGGED_PAGINATED, {
      getTimeLoggedPaginated: res.result,
      totalNumberOfTimeLogged: res.totalNumberOfElements,
      totalNumberOfTimeLoggedPages: res.totalNumberOfPages,
    });
  },

  async getEmployeesLeaveRequests({ commit }, payload) {
    const response = await axios.post(
      `/employee/leave/find-all-paginated-filtered`,
      payload
    );
    const res = response.data.response;
    commit(GET_EMPLOYEE_LEAVE_REQUESTS, {
     employeesLeaveRequests: res.result,
      totalNumberOfLeaveRequests: res.totalNumberOfElements,
      totalNumberOfLeaveRequestsPages: res.totalNumberOfPages,
    });
  },
  async putEmployeeLeaveAction(context, payload){
    const response = await NetworkManager.put(`/employee/leave`, payload);
    context.commit( PUT_EMPLOYEE_LEAVE_ACTION, response);
  }

  // /employee/leave

  // PUT_EMPLOYEE_LEAVE_ACTION  putEmployeeLeaveAction

            // [PUT_EMPLOYEE_LEAVE_ACTION](state, payload) {
            //     state.putEmployeeLeaveAction = payload;
            //   }



};
