import vuetifyEn from "vuetify/lib/locale/en";

const en = {
  $vuetify: {
    ...vuetifyEn,
  },
  form: {
    is_required: "is required",
    must_be_valid: "must be valid",
  },
  datatable: {
    search: "Search...",
    loading: "Loading... Please wait",
    actions: "Actions",
  },
  buttons: {
    save: "Save",
    cancel: "Cancel",
    choose_file: "Choose File",
    submit: "Submit",
  },
  actions: {
    logout: "Logout",
    export: "Export",
    done: "Done",
  },
  bool: {
    yes: "Yes",
    no: "No",
  },
  locales: {
    en: "English",
    ar: "Arabic",
  },
  InputSelect: {
    options: "selected options",
  },
  rules: {
    please_enter: "Please enter",
    please_enter_positive_value: "Please enter a positive number",
    only_letters: "Must only letters",
    only_numbers: "Must be only numbers",
    at_least_digits_letters: "at least of digits or letters",
    at_least_digits: "at least of digits",
    email_invalid: "Invalid email",
    phone_invalid: "Invalid phone",
    please_enter_range_from: "Please enter from",
    please_enter_at_least: "Please enter at least",
    please_enter_range_to: "to",
    One_lowercase_character: "must contain lowercase character",
    One_uppercase_character: "must contain uppercase character",
    One_number: "must contain number",
    special_character: " must contain a symbol like: # - @ ....",
  },
  snackbar: {
    close: "Close",
  },
  employees: {
    pageTitle: "Employees",
    uploadEmployeeTitle: "Upload Employees",
    searchTxt: "Search",
    reset: "Reset",
    id: "ID",
    email: "Email",
    firstName: "First Name",
    lastName: "Last Name",
    department: "Department",
    jobTitle: "Job Title",
    location: "Location",
    actions: "Actions",
    newEmployee: "New Employee",
    createNewEmployee: "Create New Employee",
    uploadNewEmployee: "Upload Employeess",
    requiredDocuments: "Specify Required Documents",
    listRequiredDocuments: "Required Documents",
    addRequiredDocuments: "Add Required Documents",
    createNewEmployeeTitle: "Create New Employee",
    updateEmployeeTitle: "Employee's Data",
    history: "Employee's History",
    filters: {
      departmentsPlaceholder: "Select department(s)",
      locationsPlaceholder: "Select location(s)",
      clientNamePlaceholder: "Select Client Name",
      jobTitlesPlaceholder: "Select jobTitle(s)",
      firstNamePlaceholder: "Enter First Name",
      lastNamePlaceholder: "Enter Last Name",
    },
    employeeForm: {
      fName: {
        label: "First Name",
        placeholder: "Enter First Name",
      },
      middleName: {
        label: "Middle Name",
        placeholder: "Enter Middle Name",
      },
      lName: {
        label: "Last Name",
        placeholder: "Enter Last Name",
      },
      email: {
        label: "Email",
        placeholder: "Enter Email",
      },
      phone: {
        label: "Phone",
        placeholder: "Enter Phone",
      },
      jobTitle: {
        label: "Job title",
        placeholder: "Enter Job title",
      },
      location: {
        label: "Location",
        placeholder: "Enter Location",
      },
      joiningDate: {
        label: "Joining Date",
        placeholder: "Enter Joining Date",
      },
      department: {
        label: "Department",
        placeholder: "Enter Department",
      },
      levelNumber: {
        label: "Level Number",
        placeholder: "Enter Level Number",
      },
      altEmail: {
        label: "Alternative Email",
        placeholder: "Alternative Email",
      },
      creationDate: {
        label: "Creation Date",
        placeholder: "Enter Creation Date",
      },
      contractExpiryDate: {
        label: "Contract Expiry Date",
        placeholder: "Enter Contract Expiry Date",
      },
      birthDate: {
        label: "Birth Date",
        placeholder: "Enter Birth Date",
      },
      employeeStatus: {
        label: "Employee Status",
        placeholder: "Enter Employee Status",
      },
      grossSalary: {
        label: "Gross Salary",
        placeholder: "Enter Gross Salary",
      },
      maritalStatus: {
        label: "Marital Status",
        placeholder: "Enter Marital Status",
        single: "SINGLE",
        married: "MARRIED",
        widower: "WIDOWER",
        divorced: "DIVORCED",
      },
      militaryStatus: {
        label: "Military Status",
        placeholder: "Enter Military Status",
      },
      socialInsuranceNumber: {
        label: "Social Insurance Number",
        placeholder: "Enter Social Insurance Number",
      },
      workId: {
        label: "Work Id",
        placeholder: "Enter Work Id",
      },
      msgs: {
        create: "Employee was added successfully",
        update: "Employee was updated successfully",
      },
      currency: {
        label: "Currency",
        placeholder: "Enter Currency",
      },
      btns: {
        create: "Create",
        update: "Update",
      },
      requiredDocfieldName: {
        label: "Field Name",
        placeholder: "Kindly Enter Field Name",
      },
    },
  },
  projects: {
    pageTitle: "Projects",
    projectDetails: "Project Details",
    addProject: "Create Project",
    updateProject: "Update Project",
    id: "Id",
    name: "Name",
    email: "Email",
    loggedHours: "Logged Hours",
    description: "Description",
    clientName: "Client Name",
    department: 'Department',
    assignEmployees: 'Assign Employees',
    chooseEmployeesToAssign: "Choose Employees To Assign",
    assignedEmployees: "Employees Assigned To Project",
    showDetails: "Show Details",
    loggedTime: "Logged Time",

    edit: "Edit",
    projectForm: {
      role: {
        title: "Add Role",
        label: "Role",
        placeholder: "Enter Role",
        update: "Update Role",

      },
      description: {
        label: "description",
        placeholder: "Enter description",
      },
      client: {
        title: "Add Client",
        label: "Client Name",
        placeholder: "Enter Client Name",
        update: "Update Client",

      },
      name: {
        label: "Project name",
        placeholder: "Enter Project name",
      },

    },
  },
  timeLogged: {
    employeeEmail: "Employee Email",
    employee: "Employee",
    project: "Project",
    totalHours: "Total Hours",
    employeeComment: "ِEmployee Comment",
    adminComment: "Admin Comment",
    leave: "Leave",
    date: "Date",
    from: "From",
    to: "To",
    SelectProject: "Select Project",
    selectStatus: "Select Status",
    selectType: "Select Type",
    type: "Type",
    status: "Status",
    action: "Action"

  },
  employeeLeaves: {
    EmployeeLeavesTitle: "Employee Leaves Requests",
  }
};

export default en;