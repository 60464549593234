import Vue from "vue";
import Vuex from "vuex";
import { SNACKBAR_TYPES } from "../constants/index.js";
import authModule from "./modules/auth/index.js";
import employeesModule from "./modules/employees/index";
import projectsModule from "./modules/projects/index";
import timelogsModule from "./modules/timelog/index";


import { SET_SNACKBAR } from "./mutation-types.js";

Vue.use(Vuex);



export default new Vuex.Store({
	state: {
		snackbar: {
			isOpen: false,
			text: "",
			type: SNACKBAR_TYPES.ERROR
		}
	},
	mutations: {
		[SET_SNACKBAR](state, payload) {
			state.snackbar.isOpen = payload.isOpen;
			state.snackbar.text = payload.text;
			state.snackbar.type = payload.type;
		}
	},
	actions: {
		setSnackbar({ commit }, { isOpen, text, type }) {
			commit(SET_SNACKBAR, {
				isOpen,
				text,
				type
			});
		}
	},
	getters: {
		snackbar: (state) => state.snackbar
	},
	modules: {
		auth: authModule,
		employees: employeesModule,
		projects:projectsModule,
		timelog:timelogsModule
	},
});
