import {
  SET_EMPLOYEE_TABLE,
  SET_EMPLOYEE,
  SET_DEPARTMENTS,
  SET_LOCATIONS,
  SET_JOB_TITLES,
  SET_CURRENCY,
  UPLOAD_EMPLOYEE,
  ADD_UPLOEADED_EMPLOYEE,
  UPDATE_UPLOEADED_EMPLOYEE,
  GET_EMPLOYEE_HISTORY,
  GET_REQUIRED_DOCUMENTS,
  GET_DOCUMENTS_PAGINATED,
  POST_REQUIRED_DOCUMENTS,
  GET_UPLOADED_DOCUMENTS,
  DELETE_UPLOADED_DOCUMENTS,
  ADD_REQUIRED_DOCUMENTS,
  EDIT_REQUIRED_DOCUMENTS
} from "../../mutation-types";



export default {
  [SET_EMPLOYEE_TABLE](state, payload) {
    state.employees = payload.employees;
    state.totalNumberOfEmployees = payload.totalNumberOfEmployees;
    state.totalNumberOfPages = payload.totalNumberOfPages;
  },
  [SET_EMPLOYEE](state, payload) {
    state.employee = payload;
  },
  [SET_DEPARTMENTS](state, payload) {
    state.departments = payload;
  },
  [SET_LOCATIONS](state, payload) {
    state.locations = payload;
  },
  [SET_JOB_TITLES](state, payload) {
    state.jobTitles = payload;
  },
  [SET_CURRENCY](state, payload) {
    state.currency = payload
  },
  [UPLOAD_EMPLOYEE](state, payload) {
    state.uploadEmplyees = payload;

  },
  [ADD_UPLOEADED_EMPLOYEE](state, payload) {
    state.postUploadedEmployee = payload;
  },
  [UPDATE_UPLOEADED_EMPLOYEE](state, payload) {
    state.updateUploadedEmployee = payload;
  },
  [GET_EMPLOYEE_HISTORY](state, payload){
    state.getEmployeeHistory = payload
  },
  [GET_REQUIRED_DOCUMENTS](state, payload){
    state.getRequiredDocuments = payload
  },
  [GET_DOCUMENTS_PAGINATED](state, payload){
    state.getDocumentsPaginated = payload.getDocumentsPaginated;
    state.totalNumberOfDocs = payload.totalNumberOfDocs;
    state.totalNumberOfDocsPages = payload.totalNumberOfDocsPages;
  },
  [POST_REQUIRED_DOCUMENTS](state, payload){
    state.postRequiredDocuments = payload
  },
  [GET_UPLOADED_DOCUMENTS](state, payload){
    state.getUploadedDocuments = payload
  },
  [DELETE_UPLOADED_DOCUMENTS](state, payload){
    state.deleteUploadedDocuments = payload
  },
  [ADD_REQUIRED_DOCUMENTS](state, payload){
    state.addRequiredDocuments = payload
  },
  [EDIT_REQUIRED_DOCUMENTS](state, payload){
    state.editRequiredDocuments = payload
  },

  // EDIT_REQUIRED_DOCUMENTS editRequiredDocuments


};