import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      // PROJECT ROLES
      postProjectRoles: [],
      getAllProjectRoles: [],
      getProjectRoles: [],
      totalNumberOfRoles: 0,
      totalNumberOfRolesPages: 0,
      getRoleById: [],
      editRole: [],
      // PROJECT CLIENTS
      postProjectClients: [],
      getAllProjectClients: [],
      getProjectClients: [],
      totalNumberOfClients: 0,
      totalNumberOfClientsPages: 0,
      getClientById: [],
      editClient: [],
      /// /// ///
      addProject: [],
      editProject: [],
      getAllProjects: [],
      getSingleProject: [],
      /// /// ///
      projects: [],
      totalNumberOfProjects: 0,
      totalNumberOfProjectPages: 0,
      /// /// ///
      assignEmployeesToProject: [],
      getEmployeesNotAssignedToProjects: [],
      /// /// ///
      notAssignedEmployees: [],
      totalNumberOfnotAssignedEmployees: 0,
      totalNumberOfPagesOfNotAssignedEmployees: 0,
      /// /// ///
      getTimeLoggedPerProject: [],
    };
  },
  mutations,
  actions,
  getters,
};

// totalNumberOfRoles: 0,
// 			totalNumberOfRolesPages: 0,

// totalNumberOfClients: 0,
// totalNumberOfRolesClients: 0,
