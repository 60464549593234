import Vue from "vue";
import VueRouter from "vue-router";
// import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/employees/employees-list",
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue"),
    // meta: { requiresAdmin: true }
  },
  {
    path: "/table",
    name: "Table",
    component: () => import("../views/Table.vue"),
    // meta: { requiresAdmin: true }
  },
  {
    path: "/employees/employees-list",
    name: "Employees List",
    component: () => import("../views/Employee-module/EmployeesList.vue"),
    // meta: { requiresAdmin: true }
  },
  {
    path: "/employees/upload-employees",
    name: "UploadEmployees",
    component: () => import("../views/Employee-module/EmployeeUpload.vue")
  },
  {
    path: "/employees/create",
    name: "EmployeesCreate",
    component: () => import("../views/Employee-module/EmployeeCreate.vue"),
    // meta: { requiresAdmin: true }
  },
  {
    path: "/employees/update/:id",
    name: "EmployeesUpdate",
    component: () => import("../views/Employee-module/EmployeeCreate.vue"),
    // meta: { requiresAdmin: true }
  },
  {
    path: "/employees/add/required-documents", //commented component
    name: "addRequiredDocuments",
    component: () => import("../views/Employee-module/RequiredDocuments.vue"),
    // meta: { requiresAdmin: true }
  },
  {
    path: "/employees/edit/required-documents", //commented component
    name: "editRequiredDocuments",
    component: () => import("../views/Employee-module/RequiredDocuments.vue"),
    // meta: { requiresAdmin: true }
  },
  {
    path: "/employees/list-required-documents", //commented component
    name: "ListRequiredDocuments",
    component: () => import("../views/Employee-module/ListRequiredDocuments.vue"),
    // meta: { requiresAdmin: true }
  },
  {
    path: "/employees/upload-required-documents/:id",
    name: "UploadRequiredDocuemnts",
    component: () => import("../views/Employee-module/UploadRequiredDocuemnts.vue"),
    // meta: { requiresAdmin: true }
  },
  {
    path: "/projects/projects-list",
    name: "Projet List",
    component: () => import("../views/project-module/ProjectList.vue")
  },
  {
    path: "/projects/create-project",
    name: "Create Projet",
    component: () => import("../views/project-module/CreateProject.vue")
  },
  {
    path: "/projects/:id",
    name: "updateProjet",
    component: () => import("../views/project-module/CreateProject.vue")
  },
  {
    path: "/projects/assign-employees/:id",
    name: "Assign Employees",
    component: () => import("../views/project-module/assignEmployees.vue")
  },
  {
    path:"/projects/add/role",
    name:"addRole",
    component:()=>import("../views/project-module/AddRole.vue")
  },
  {
    
    path:"/projects/update/role/:id",
    name:"updateRole",
    component:()=>import("../views/project-module/AddRole.vue")
  },
  {
    path:"/projects/list/roles",
    name:"listRoles",
    component:()=>import("../views/project-module/ListRoles.vue")
  },
  {
    path:"/projects/add/client",
    name:"listClients",
    component:()=>import("../views/project-module/AddClient.vue")
  },
  {
    path:"/projects/update/client/:id",
    name:"updateClient",
    component:()=>import("../views/project-module/AddClient.vue")
  },
  {
    path:"/projects/list/clients",
    name:"addClient",
    component:()=>import("../views/project-module/ListClients.vue")
  },
  {
    path:"/projects/details/logged-time/:id",
    name:"loggedProjectTime",
    component:()=>import("../views/project-module/LoggedTime.vue")
  },
  {
    path:"/time-log/",
    name:"loggedTime",
    component:()=>import("../views/TimeLog-module/TimeLog.vue")
  },
  {
    path:"/time-log/Employee-leaves",
    name:"leaveRequests",  
    component:()=>import("../views/TimeLog-module/EmployeeLeave.vue")
    // /time-log/Employee-leaves
  },

];

const router = new VueRouter({
  // Prefix for all routes
  mode: "history",
  routes,
});

// router.beforeEach((to, from, next) => {
// 	if (to.matched.some((record) => record.meta.requiresAdmin)) {
// 		// this route requires admin

// 		// Check if logged in and set user store object
// 		if (localStorage.getItem("loggedIn")) {
// 			if (
// 				Vue.$keycloak.token &&
// 				Vue.$keycloak.idToken &&
// 				Vue.$keycloak.token != "" &&
// 				Vue.$keycloak.idToken != ""
// 			) {
// 				let payload = {
// 					username: Vue.$keycloak.subject,
// 					roles: Vue.$keycloak.tokenParsed.realm_access.roles,
// 					idToken: Vue.$keycloak.idToken,
// 					accessToken: Vue.$keycloak.token
// 				};
// 				store.dispatch("auth/login", payload);
// 			}
// 		}
// 		next();
// 	} else {
// 		next(); // make sure to always call next()!
// 	}
// });

export default router;
