<template>
  <v-app>
    <app-drawer />
    <v-main>
      <v-container class="p-5 m-0" fluid>
        <snackbar />
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppDrawer from "./components/layout/drawer.vue";
import Snackbar from "./components/layout/snackbar.vue";

export default {
  name: "App",
  components: { AppDrawer, Snackbar },
};
</script>
<style lang="scss">
</style>