export default {
	// PROJECT ROLES
	postProjectRoles: (state) => state.postProjectRoles,
	getAllProjectRoles: (state) => state.getAllProjectRoles,
	getProjectRoles: (state) => state.getProjectRoles,
	totalNumberOfRoles: (state) => state.totalNumberOfRoles,
	totalNumberOfRolesPages: (state) => state.totalNumberOfRolesPages,
	getRoleById: (state) => state.getRoleById,
	editRole: (state) => state.editRole,
	// PROJECT CLIENTS
	postProjectClients: (state) => state.postProjectClients,
	getAllProjectClients: (state) => state.getAllProjectClients,
	getProjectClients: (state) => state.getProjectClients,
	totalNumberOfClients: (state) => state.totalNumberOfClients,
	totalNumberOfClientsPages: (state) => state.totalNumberOfClientsPages,
	getClientById: (state) => state.getClientById,
	editClient: (state) => state.editClient,
	//////
	getAllProjects: (state) => state.getAllProjects,
	addProject: (state) => state.addProject,
	getSingleProject: (state) => state.getSingleProject,
	projects: (state) => state.projects,
	totalNumberOfProjects: (state) => state.totalNumberOfProjects,
	totalNumberOfProjectPages: (state) => state.totalNumberOfProjectPages,
	assignEmployeesToProject: (state) => state.assignEmployeesToProject,
	editProject: (state) => state.editProject,
	getEmployeesNotAssignedToProjects: (state) => state.getEmployeesNotAssignedToProjects,
	notAssignedEmployees: (state) => state.notAssignedEmployees,
	totalNumberOfnotAssignedEmployees: (state) => state.totalNumberOfnotAssignedEmployees,
	totalNumberOfPagesOfNotAssignedEmployees: (state) => state.totalNumberOfPagesOfNotAssignedEmployees,
	getTimeLoggedPerProject: (state) => state.getTimeLoggedPerProject,
}



// projects:[],
// totalNumberOfProjects: 0,
// totalNumberOfProjectPages: 0,