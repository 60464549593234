<template>
  <v-navigation-drawer app :right="$i18n.locale === 'ar'" :color="'gray'">
    <v-layout fill-height column>
      <div class="d-flex flex-column align-center mb-1 img-wrapper">
        <v-img src="../../assets/images/ntsal-logo.svg" id="img-logo" class="flex-grow-0 flex-shrink-0"
          max-width="130" />
        <v-divider class="divider ms-4 me-5" style="opacity: 0.16"></v-divider>
      </div>
      <v-list flat class="flex-grow-1 menu-items">
        <v-list-item-group v-model="selectedItem" mandatory>
          <!-- <template v-for="(item, index) in filteredDrawerItems">
            <v-list-item :key="index">
              <router-link class="anchor px-2" :to="`/${item.path}`">
                <v-list-item-content>
                  <v-list-item-title class="menu-text white--text">
                    {{ item[`title_${$i18n.locale}`] }}
                  </v-list-item-title>
                </v-list-item-content>
   
              </router-link>
            </v-list-item>
            <v-divider
              :key="'divider' + index"
              class="mb-3 divider"
              style="opacity: 0.16"
            ></v-divider>
          </template> -->
          <template>
            <v-expansion-panels>
              <v-expansion-panel v-for="(item, i) in filteredDrawerItems" :key="i">
                <v-expansion-panel-header>
                  <h3> {{ item[`title_${$i18n.locale}`] }}
                  </h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ul v-for="(x, i) in filteredDrawerItems[i].subMenu" :key="i">
                    <li>
                      <router-link class="anchor" :to="`/${item.path}/${x.path}`">
                        {{ x[`title_${$i18n.locale}`] }}
                      </router-link>

                    </li>
                  </ul>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
        </v-list-item-group>
      </v-list>
    </v-layout>
  </v-navigation-drawer>
</template>

        </v-list-item-group>
      </v-list>

    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

// http://localhost:8080/employees/create

let menuItems = [
  {
    title_en: "Employees",
    title_ar: "الموظفين",
    path: "employees",
    icon: "employees",
    avatar: true,
    subMenu: [
      {
        title_en: "Employees List",
        title_ar: "الموظفين",
        path: "employees-list",
        icon: "employees",
      },
      // {
      //   title_en: "Add Employees",
      //   title_ar: "إضافة موظف",
      //   path: "create",
      //   icon: "employees",
      // },
      {
        title_en: "Upload Employees",
        title_ar: "إضافة موظفين",
        path: "upload-employees",
        icon: "employees",
      },
      {
        title_en: "Documents",
        title_ar: " الملفات",
        path: "list-required-documents",
        icon: "employees",
      },
    ],
  },
  {
    title_en: "Projects List",
    title_ar: "المشروعات",
    path: "projects",
    icon: "projects",
    avatar: true,
    subMenu: [
      {
        title_en: "Projects",
        title_ar: "المشروعات",
        path: "projects-list",
        icon: "projects",
      },
      // {
      //   title_en: "Create Project",
      //   title_ar: "إضافه مشروع",
      //   path: "create-project",
      //   icon: "projects",
      // },
      {
        // /projects/list/roles
        // /projects/list/roles
        title_en: "Roles",
        title_ar: "قائمة الوظائف",
        path: "list/roles",
        icon: "projects",
      },
      {
        title_en: "Clients",
        title_ar: "قائمة العملاء",
        path: "list/clients",
        icon: "projects",
      }
    ]
  },
   {
    title_en: "Time Log",
    title_ar: "الوقت المسجل",
    path: "time-log",
    icon: "Time Log",
    avatar: true,
        subMenu: [
      {
        title_en: "Time Log List",
        title_ar: "قائمه الوقت المسجل",
        path: "",
        icon: "projects",
      },
      {
        title_en: "Employee Leave Requests",
        title_ar: "طلبات إجازة الموظف",
        path: "Employee-leaves",
        icon: "projects",
      },
        ]
   }
];

export default {
  name: "AppDrawer",
  data() {
    return {
      selectedItem: 0,
      languages: this.$i18n.availableLocales,
      drawerItems: menuItems,
    };
  },
  computed: {
    ...mapGetters("auth", ["roles", "bankID"]),
    filteredDrawerItems() {
      return this.drawerItems;
    },
  },
  methods: {
    menuItemColor(index) {
      console.log(index);
      return true;
    },
    menuIconColor(index) {
      return this.selectedItem === index ? "#E8AE1E" : "#fff";
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
    // changeLang(lang) {
    //   this.$vuetify.rtl = lang === "ar";
    //   this.$i18n.locale = lang;
    //   this.$moment.locale(lang);
    // },
  },
  created() {
    // this.loadBank();
  },
  watch: {
    "$route.path": {
      handler: function (path) {
        this.selectedItem = this.filteredDrawerItems.findIndex(
          (item) => item.path === path.replace("/", "")
        );
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.img-wrapper {
  min-height: 8rem;

  #img-logo {
    margin: 1.5rem 2rem 1.625rem 2rem;
  }
}

.router-link-active,
.router-link-exact-active {
  background-color: brown;
}

.menu-items {
  .v-list-item {
    padding: 0.375rem 0;

    a {
      display: flex;
      width: 100%;
    }

    .v-list-item__icon {
      &:first-child {
        margin-left: 0 !important;
        margin-right: 0 !important;
        align-self: center;
      }

      .menu-icon {
        width: 1.25rem;
        max-width: 1.25rem;
        margin-inline-end: 0.8125rem;
      }
    }

    .menu-text {
      font-size: 1rem;
      font-weight: $font-medium;
      letter-spacing: 0.4px;
    }

    .cases-counter {
      font-size: 0.875rem;
    }
  }
}

.v-expansion-panels {
  border-radius: 0px !important;
}

.theme--light .v-expansion-panels .v-expansion-panel {
  background-color: transparent !important;

  ul {
    list-style-type: none;
    padding: 0;
  }
}

.v-application a {
  color: #000;
}

.router-link-exact-active {
  background-color: transparent !important;
  font-weight: bold !important;

}


::v-deep #btn-lang {
  min-width: 6.2rem;
  max-width: fit-content;
  padding-inline-start: 0.5rem;
  padding-inline-end: 0.875rem;
  background-color: rgba($color: #fff, $alpha: 0.1) !important;
  border: 1px solid rgba($color: #fff, $alpha: 0.1) !important;

  &.bank {
    border-color: rgba($color: #282828, $alpha: 0.1) !important;
  }

  .v-btn__content {
    font-size: 0.8125rem;
    font-weight: $font-medium;
    letter-spacing: 0.33px;
  }
}

.v-application--is-ltr .v-btn__content .v-icon--left {
  margin-left: 0;
}
</style>