export const SET_SNACKBAR = "SET_SNACKBAR";
export const SET_EMPLOYEE_TABLE = "SET_EMPLOYEE_TABLE";
export const SET_EMPLOYEE = "SET_EMPLOYEE";
export const SET_DEPARTMENTS = "SET_DEPARTMENTS";
export const SET_LOCATIONS = "SET_LOCATIONS";
export const SET_JOB_TITLES = "SET_JOB_TITLES";
export const SET_CURRENCY = 'SET_CURRENCY'
export const UPLOAD_EMPLOYEE = "UPLOAD_EMPLOYEE";
export const ADD_UPLOEADED_EMPLOYEE = "ADD_UPLOEADED_EMPLOYEE";
export const UPDATE_UPLOEADED_EMPLOYEE = "UPDATE_UPLOEADED_EMPLOYEE";
export const GET_EMPLOYEE_HISTORY ="GET_EMPLOYEE_HISTORY";
export const GET_REQUIRED_DOCUMENTS = "GET_REQUIRED_DOCUMENTS";
export const GET_DOCUMENTS_PAGINATED = "GET_DOCUMENTS_PAGINATED";
export const POST_REQUIRED_DOCUMENTS = "POST_REQUIRED_DOCUMENTS";
export const GET_UPLOADED_DOCUMENTS = "GET_UPLOADED_DOCUMENTS";
export const DELETE_UPLOADED_DOCUMENTS = "DELETE_UPLOADED_DOCUMENTS";
// addRequiredDocuments ADD_REQUIRED_DOCUMENTS
export const ADD_REQUIRED_DOCUMENTS = "ADD_REQUIRED_DOCUMENTS";
export const EDIT_REQUIRED_DOCUMENTS = "EDIT_REQUIRED_DOCUMENTS"

// EDIT_REQUIRED_DOCUMENTS,


// project

export const POST_PROJECT_ROLES = "POST_PROJECT_ROLES";
export const GET_ALL_PROJECT_ROLES = "GET_ALL_PROJECT_ROLES"; // NOT PAGINATED FOR DROPDOWNS
export const GET_PROJECT_ROLES = "GET_PROJECT_ROLES"; //PAGINATED FOR LISTING
export const GET_ROLE_BY_ID ="GET_ROLE_BY_ID"
export const EDIT_ROLE ="EDIT_ROLE"

export const POST_PROJECT_CLIENTS = "POST_PROJECT_CLIENTS";
export const GET_ALL_PROJECT_CLIENTS = "GET_ALL_PROJECT_CLIENTS"; // NOT PAGINATED FOR DROPDOWNS
export const GET_PROJECT_CLIENTS = "GET_PROJECT_CLIENTS"; //PAGINATED FOR LISTING
export const GET_CLIENT_BY_ID ="GET_CLIENT_BY_ID"
export const EDIT_CLIENT ="EDIT_CLIENT"


export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const GET_ALL_PROJECTS_PAGINATED = "GET_ALL_PROJECTS_PAGINATED";
export const GET_SINGLE_PROJECT ="GET_SINGLE_PROJECT"
export const ADD_PROJECT = "ADD_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";

export const ASSIGN_EMPLOYEES_TO_PROJECT = "ASSIGN_EMPLOYEES_TO_PROJECT";
export const GET_NOT_ASSIGNED_EMPLOYEES = "GET_NOT_ASSIGNED_EMPLOYEES";
export const GET_TIME_LOGGED_PER_PROJECT = "GET_TIME_LOGGED_PER_PROJECT";

// log time module

export const GET_TIME_LOGGED_PAGINATED = "GET_TIME_LOGGED_PAGINATED";
export const GET_EMPLOYEE_LEAVE_REQUESTS = "GET_EMPLOYEE_LEAVE_REQUESTS" ;
export const PUT_EMPLOYEE_LEAVE_ACTION = "PUT_EMPLOYEE_LEAVE_ACTION";